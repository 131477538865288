.route {
  width: 100vw;
  height: 100vh;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

button.button {
  position: fixed;
  z-index: 5000;
  bottom: 1rem;
  left: 1rem;
}
