@import 'variables';

:root {
  --fs: calc(38vh / 1080 * 100);
  --fw: #{$font-weight-regular};
  --ff: #{$font-family};

  --fw-regular: #{$font-weight-regular};
  --fw-medium: #{$font-weight-medium};
  --fw-bold: #{$font-weight-bold};

  // colors
  --primary-color: #{$primary-color};
  --secondary-color: #{$secondary-color};
  --secondary-light-color: #{$secondary-light-color};
  --success-color: #{$success-color};
  --error-color: #{$error-color};
  --warning-color: #{$warning-color};
  --beige-color: #{$beige-color};
  --white-color: #{$white-color};
  --light-color: #{$light-color};
  --color: var(--primary-color);
}
