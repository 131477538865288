.root {
  background: #f6f1ec;
  padding: 0.5em;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  overflow: hidden;
  position: relative;

  img {
    width: 100%;
  }

  .content {
    width: calc(100vw - 1em);
    max-height: 1012.44px;
    flex: 1;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
  }

  .subText {
    background: white;
    width: 100%;
    margin-bottom: -2px;
    z-index: 1;
    display: flex;
    flex-direction: column;
    font-size: 0.5em;
    font-weight: 400;
    padding: 0.5em 1em 0 1em;

    strong {
      font-weight: 600;
      margin-right: 0.3em;
    }
    span {
      height: 4em;
      text-overflow: ellipsis;

      display: -webkit-box;
      max-width: 100%;
      line-height: 1.4;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }

  .imgWrapper {
    width: 100%;
    height: 100%;
  }

  .clock {
    color: black;
    position: absolute;
    top: 71px;
    left: 121px;
    font-size: 37px;
    font-weight: 500;
  }
}
