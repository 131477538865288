.root {
  .background {
    background-color: #f7f1eb;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -4;
  }

  .header {
    position: fixed;
    top: 0;
    width: 100%;
  }

  .logo {
    position: fixed;
    top: 1.2rem;
    left: 2rem;
    height: 1.2rem;
  }

  .coin {
    position: fixed;
    top: 12px;
    right: 12px;
  }

  .footer {
    position: fixed;
    bottom: 0;
    width: 100%;
  }

  .commentContainer {
    $shadowOffset: 16px;
    margin: 0.5em 1em 1em 1em;
    max-height: 70%;
    position: relative;

    .comment {
      font-size: 0.7em;
      position: relative;

      .main,
      .mainShadow {
        border-radius: 16px;
      }

      .main {
        padding: 200px 1em 1em 1em;
        background: white;

        &.dark {
          background: #d9e6ec;
        }

        &.dark::after {
          content: '';
          height: $shadowOffset;
          width: 100%;
          background: white;
          position: absolute;
          bottom: calc(-#{$shadowOffset});
          z-index: -2;
        }

        .stars {
          color: #ff4d4d;
          margin-bottom: 0.4em;
        }

        .commentCoin {
          width: 274px;
          position: absolute;
          top: -100px;
          left: 50%;
          margin-left: -137px;
        }

        .text {
          margin-bottom: 1.5em;
        }

        strong {
          font-weight: 600;
        }
      }

      .mainShadow {
        background: #f9e7de;
        right: calc(-#{$shadowOffset});
        bottom: calc(-#{$shadowOffset});
        top: $shadowOffset;
        left: 0;
        position: absolute;
        z-index: -1;

        &.dark {
          background: #d9e6ec;
          opacity: 0.5;
        }
      }

      .triangle {
        $left: 125px;
        $bottom: -200px;

        position: absolute;
        bottom: $bottom;
        left: $left;
        display: inline-block;
        width: 400px;
        color: white;
        filter: url('#goo');

        &.dark:not(.shadow) {
          color: #d9e6ec;
        }

        &::before {
          content: '';
          display: block;
          background: currentColor;
          padding-top: 60%;
          clip-path: polygon(0 0, 0% 100%, 100% 0);
        }

        &.shadow {
          z-index: -3;
          bottom: calc(#{$bottom} - #{$shadowOffset});
          left: calc(#{$left} + #{$shadowOffset});
          color: #f9e7de;

          &.dark {
            color: #d9e6ec;
            opacity: 0.5;
          }
        }
      }
    }
  }
}
