@import 'variables';
@import 'fonts';
@import 'custom-properties';

:root {
  font-size: var(--fs);
  font-weight: var(--fw);
  font-family: var(--ff);
  margin: 0;
  padding: 0;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--color);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  width: 100%;
  height: 100%;
}
